import { createIcon } from "@chakra-ui/icons";

export const CopyIcon = createIcon({
  displayName: "HashIcon",
  defaultProps: {
    width: "20px",
    height: "20px",
  },
  viewBox: "0 0 20 20",
  path: (
    <>
      <g id="CopyIcon">
      {/* Нижний (задний) прямоугольник */}
      <rect
        x="0"
        y="0"
        width="14"
        height="14"
        rx="2"
        ry="2"
        fill="none"
        stroke="currentColor"
        strokeWidth="1"
      />
      {/* Верхний (передний) прямоугольник */}
      <rect
        x="4"
        y="4"
        width="14"
        height="14"
        rx="2"
        ry="2"
        fill="none"
        stroke="currentColor"
        strokeWidth="1"
      />
    </g>
    </>
  ),
});
