import { ReactElement, ReactNode } from 'react';
import lazyWithRetry from '@/helpers/lazyWithRetry';
import {
  DeliveryIcon,
  HelpIcon,
  MyOrdersicon,
  NewOrderIcon,
  NotificationsIcon,
  TransactionsHistoryIcon,
  CommentIcon,
  CargoIcon
} from '@/components/atoms/icons';

export interface RouteType {
  id: string;
  title: string;
  path: string;
  element: ReactElement;
  children?: RouteType[];
  forSidebar?: boolean;
  icon?: ReactNode;
}

const Landing = lazyWithRetry(() => import('@/pages/Landing/Landing'));
const BlogLayout = lazyWithRetry(() => import('@/layouts/BlogLayout'));
const Post = lazyWithRetry(() => import('@/pages/Blog/Post'));
const MainBlog = lazyWithRetry(() => import('@/pages/Blog/Main'));
const Root = lazyWithRetry(() => import('@/pages/Root'));
const Auth = lazyWithRetry(() => import('@/pages/Auth/Auth'));
const NewOrder = lazyWithRetry(() => import('@/pages/NewOrder/NewOrder'));
const MyOrders = lazyWithRetry(() => import('@/pages/MyOrders/MyOrders'));
const SingleOrder = lazyWithRetry(() => import('@/pages/MyOrders/SingleOrder'));
const OrderProduct = lazyWithRetry(
  () => import('@/pages/MyOrders/OrderProduct')
);
const Steps = lazyWithRetry(() => import('@/pages/MyOrders/Steps'));
const Delivery = lazyWithRetry(() => import('@/pages/Delivery/Delivery'));
const Transactions = lazyWithRetry(
  () => import('@/pages/Transactions/Transactions')
);
const Backet = lazyWithRetry(() => import('@/pages/Backet/Backet'));
const Favorites = lazyWithRetry(() => import('@/pages/Favorites/Favorites'));
const Profile = lazyWithRetry(() => import('@/pages/Profile/Profile'));
const Notifications = lazyWithRetry(
  () => import('@/pages/Notifications/Notifications')
);
const Comments = lazyWithRetry(() => import('@/pages/Comments/Comments'));
const Deposit = lazyWithRetry(() => import('@/pages/Deposit/Deposit'));
const Help = lazyWithRetry(() => import('@/pages/Help/Help'));
const NotFound = lazyWithRetry(() => import('@/pages/404/404'));
const Cargo = lazyWithRetry(() => import('@/pages/Cargo/Cargo'));
const CargoOrders = lazyWithRetry(() => import('@/pages/Cargo/CargoOrders'));
const CargoCreateOrder = lazyWithRetry(() => import('@/pages/Cargo/CargoCreateOrder'));

export const routes: RouteType[] = [
  {
    id: 'root',
    title: 'Доставка товаров из Китая | megatonna.kz',
    path: '/dashboard',
    element: <Root getRoutes={() => routes} />,
    children: [
      {
        id: 'new_order',
        title: 'Новый заказ',
        path: 'new-order',
        element: <NewOrder />,
        forSidebar: true,
        icon: <NewOrderIcon transition="0.2s all" />,
      },
      {
        id: 'cargo',
        title: 'Карго',
        path: 'cargo',
        element: <Cargo />,
        forSidebar: true,
        icon: <DeliveryIcon transition="0.2s all" />,
        children: [
          {
            id: 'cargo_orders',
            title: 'Карго: Мои заказы',
            path: 'orders',
            element: <CargoOrders />,
          },
          {
            id: 'cargo_create_order',
            title: 'Карго: Создать заказ',
            path: 'create',
            element: <CargoCreateOrder />,
          },
        ]
      },
      {
        id: 'my_orders',
        title: 'Мои заказы',
        path: 'my-orders',
        element: <MyOrders />,
        forSidebar: true,
        icon: <MyOrdersicon transition="0.2s all" />,
        children: [
          {
            id: 'single_orders',
            title: 'Мои заказы',
            path: ':order_id',
            element: <SingleOrder />,
          },
          {
            id: 'order_product',
            title: 'Мои заказы',
            path: ':order_id/:product_id',
            element: <OrderProduct />,
            children: [
              {
                id: 'order_steps',
                title: 'Мои заказы',
                path: 'steps',
                element: <Steps />,
              },
            ],
          },
        ],
      },
      {
        id: 'delivery',
        title: 'Доставка',
        path: 'delivery',
        element: <Delivery />,
        forSidebar: true,
        icon: <DeliveryIcon transition="0.2s all" />,
      },
      {
        id: 'notifications',
        title: 'Уведомления',
        path: 'notifications',
        element: <Notifications />,
        forSidebar: true,
        icon: <NotificationsIcon transition="0.2s all" />,
      },
      {
        id: 'comments',
        title: 'Комментарии',
        path: 'comments',
        element: <Comments />,
        forSidebar: true,
        icon: <CommentIcon transition="0.2s all" />,
      },
      {
        id: 'transactions',
        title: 'Транзакции',
        path: 'transactions',
        element: <Transactions />,
        forSidebar: true,
        icon: <TransactionsHistoryIcon transition="0.2s all" />,
      },
      {
        id: 'help',
        title: 'Помощь',
        path: 'help',
        element: <Help />,
        forSidebar: true,
        icon: <HelpIcon transition="0.2s all" />,
      },
      // {
      //   id: "search_product",
      //   title: "Поиск товара",
      //   path: "search-product",
      //   element: <Box>Поиск товара</Box>,
      //   forSidebar: true,
      //   icon: <SearchProductIcon transition="0.2s all" />,
      // },
      {
        id: 'backet',
        title: 'Поиск товара',
        path: 'backet',
        element: <Backet />,
        forSidebar: false,
      },
      {
        id: 'favorites',
        title: 'favorites',
        path: 'favorites',
        element: <Favorites />,
        forSidebar: false,
      },
      {
        id: 'profile',
        title: 'profile',
        path: 'profile',
        element: <Profile />,
        forSidebar: false,
      },
      {
        id: 'deposit',
        title: 'deposit',
        path: 'deposit',
        element: <Deposit />,
        forSidebar: false,
      },
    ],
  },
  {
    id: 'landing',
    title: 'Доставка товаров из Китая | megatonna.kz',
    path: '',
    element: <Landing />,
  },
  {
    id: 'blog',
    title: 'Блог | Доставка товаров из Китая | megatonna.kz',
    path: 'blog',
    element: <BlogLayout />,
    children: [
      {
        id: 'blogmain',
        title: 'Блог | Доставка товаров из Китая | megatonna.kz',
        path: '',
        element: <MainBlog />
      },
      {
        id: 'post',
        title: 'Статья | megatonna.kz',
        path: 'post/:article_slug',
        element: <Post />
      }
    ],
  },
  {
    id: 'auth',
    title: 'Войти | megatonna.kz - Доставка товара из Китая',
    path: 'auth',
    element: <Auth getRoutes={() => routes} />,
    children: [
      {
        id: 'register',
        title: 'Войти | megatonna.kz - Доставка товара из Китая',
        path: 'register',
        element: <Auth getRoutes={() => routes} />,
      },
      {
        id: 'forgot',
        title: 'forgot | megatonna.kz - Доставка товара из Китая',
        path: 'forgot',
        element: <Auth getRoutes={() => routes} />,
      },
    ],
  },
  {
    id: '404',
    title: 'Страница не найдена',
    path: '*',
    element: <NotFound getRoutes={() => routes} />,
  },
];
