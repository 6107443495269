import { createIcon } from "@chakra-ui/icons";

export const CargoIcon = createIcon({
  displayName: "CargoIcon",
  defaultProps: {
    width: "32px",
    height: "32px",
  },
  viewBox: "0 0 32 32",
  path: (
    <g id="GlobalCargo">
      {/* Глобус */}
      <circle
        cx="16"
        cy="16"
        r="10"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
      />
      {/* Контейнер в центре */}
      <rect
        x="10"
        y="10"
        width="12"
        height="12"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
      />
    </g>
  ),
});
